import React, {useContext, useEffect, useRef} from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import { myContext } from "../context/ElementPositionContext";

const Template = ({ data, location }) => {
  const ctx = useContext(myContext);
  const { markdownRemark, allImageSharp } = data
  const { frontmatter, html } = markdownRemark
  const imageRef = useRef(null)

  useEffect(() => {
    if (imageRef.current !== null) {
      console.log(imageRef)
      ctx.addElements({ projectMainImageTo: imageRef.current.imageRef.current, })
    }
  }, [imageRef])

  return (
    <Layout location={location}>
      <myContext.Consumer>
        {context => (
          <div className="x-center" style={{marginBottom: 32}}>
            <div className="container" style={{ marginTop: "50px" }}>
              <div className="project-post-container flush-margins">
                <div className="project-post-data">
                  <h1>{frontmatter.title}</h1>

                  <div
                    className="project-copy"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                  <h4>{frontmatter.date}</h4>
                </div>
                <div className="project-gallery flex-row-wrap" style={{height: 'fit-content'}}>
                  {frontmatter.images.map((imageName, index) => (
                    <div className="project-gallery-thumbnail" key={index}>
                      <Img
                        fluid={
                          allImageSharp.edges.filter(
                            x => x.node.fluid.originalName === imageName
                          )[0].node.fluid
                        }
                        ref={index === 0 ? imageRef : null}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </myContext.Consumer>
    </Layout>
  )
}

export default Template



export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        images
      }
    }
    allImageSharp {
      edges {
        node {
          fluid(maxWidth: 1000, quality: 100) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`